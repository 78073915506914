import React, { useCallback, useEffect, useState, useRef } from "react";

export interface IMultiRangeSlider {
    min: number;
    max: number;
    maxValue?: number;
    minValue?: number;
    stepVal?: number;
    onChange?: (arg: { min: number, max: number}) => void;
}

const MultiRangeSlider: React.FC<IMultiRangeSlider> = ({ min, max, maxValue, minValue, stepVal, onChange }) => {
  const [minVal, setMinVal] = useState(minValue || min);
  const [maxVal, setMaxVal] = useState(maxValue || max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  stepVal = stepVal || 1;
  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    // @ts-ignore
    if (range.current && range.current.style) {
      // @ts-ignore
      range.current.style.left = `${minPercent}%`;
      // @ts-ignore
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    // @ts-ignore
    if (range.current && range.current.style) {
      // @ts-ignore
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange && onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal, onChange]);

  return (
    <div className="toysrus-giftfinder-range-container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        step={stepVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        step={stepVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;

